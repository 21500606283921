.card-container {
	box-shadow: 0 20px 90px rgba(58, 46, 68, 0.05);
}

.main-box-timeline::after {
	content: '';
	position: absolute;
	left: 23px;
	top: 0;
	height: 100%;
	width: 1px;
	background-color: #c0c0c0;
	z-index: -1;
}
